"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyProgressEventType = void 0;
var JourneyProgressEventType;
(function (JourneyProgressEventType) {
    JourneyProgressEventType["ModuleStarted"] = "module_started";
    JourneyProgressEventType["ProgressAttempted"] = "progress_attempted";
    JourneyProgressEventType["DataProcessed"] = "data_processed";
    JourneyProgressEventType["DataProcessFailed"] = "data_process_failed";
    JourneyProgressEventType["ModuleCompleted"] = "module_completed";
    JourneyProgressEventType["BranchModuleCompleted"] = "branch_module_completed";
    JourneyProgressEventType["JourneyCompleted"] = "journey_completed";
    JourneyProgressEventType["StepSkipped"] = "step_skipped";
    JourneyProgressEventType["ModuleSkipped"] = "module_skipped";
    JourneyProgressEventType["SatisfactionFeedbackReceived"] = "satisfaction_feedback_received";
    JourneyProgressEventType["PracticeAttempted"] = "practice_attempted";
    JourneyProgressEventType["PartialDataAttempted"] = "partial_data_attempted";
    JourneyProgressEventType["PartialDataProcessed"] = "partial_data_processed";
    JourneyProgressEventType["PartialDataProcessFailed"] = "partial_data_process_failed";
    JourneyProgressEventType["SectionStarted"] = "section_started";
})(JourneyProgressEventType = exports.JourneyProgressEventType || (exports.JourneyProgressEventType = {}));
