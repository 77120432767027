/* eslint-disable no-useless-escape */
import { UserSession } from '@harver/journey-shared';
import { logger } from '../../utils/logger';
import {
    ChatbotData,
    ChatbotPages,
    ErrorPageChatbotDependencies,
    JourneyPageChatbotDependencies,
    LandingPageChatbotDependencies,
} from './types';
import { getSession } from '../../services/auth.service';

const BASE_ADMIN_APP_URL = process.env.REACT_APP_CANDIDATE_MANAGEMENT_BASE_URL;

export const getApplicantDetailPageLink = (
    harverAccountId: UserSession['accountId'],
    harverApplicationId: UserSession['legacyCandidateMetadataId'],
): string => {
    if (!BASE_ADMIN_APP_URL || !harverAccountId || !harverApplicationId) {
        return 'No candidate detail page';
    }
    // NOTE: This is the path for the new candidate detail page
    return `${BASE_ADMIN_APP_URL}/${harverAccountId}/candidates/${harverApplicationId}`;
};

export const getChatbotVariables = (data: unknown, language: string): ChatbotData => {
    const { currentPage } = data as JourneyPageChatbotDependencies;
    const pageUrl = window.location.pathname;

    // TODO: Eventually the OR cases can be removed since chatbot can handle this once it's configured
    const baseVariables = {
        URL: pageUrl || 'Missing URL',
        pageUrl: pageUrl || 'Missing pageUrl',
        currentPage, // TODO: This should be required. There were some issues if we set a fallback message where the bot initialized twice
        harverAccountId: 'Missing account ID',
        harverAccountName: 'Other',
        harverVacancyId: 'Missing vacancy ID',
        harverApplicationEmail: 'no-email@harver.com',
        harverApplicationId: 'Missing application ID',
        harverApplicationLanguage: language || 'Missing application language',
        harverApplicantName: 'Missing candidate name',
        harverApplicantDetailPage: 'No candidate detail page',
        harverJourneyId: 'Missing journey Id',
        harverJourneyStepId: 'Missing journey step Id',
    };

    switch (currentPage) {
        case ChatbotPages.LandingPage: {
            const { email } = data as LandingPageChatbotDependencies;

            return {
                ...baseVariables,
                harverApplicationEmail: email || baseVariables.harverApplicationEmail,
            };
        }
        case ChatbotPages.ErrorPage:
        case ChatbotPages.NotFoundPage: {
            const {
                harverAccountId,
                harverAccountName,
                harverVacancyId,
                harverApplicationEmail,
                harverApplicationId,
            } = data as ErrorPageChatbotDependencies;

            // We need to send a formatted account name because zendesk uses a variable like name for account
            const FormattedAccountName = (harverAccountName || baseVariables.harverAccountName).replace(
                /[\-\/\[\]\(\), &#'.:]/gi,
                '_',
            );

            return {
                ...baseVariables,
                harverAccountId: harverAccountId || baseVariables.harverAccountId,
                harverAccountName: FormattedAccountName,
                harverVacancyId: harverVacancyId || baseVariables.harverVacancyId,
                harverApplicationEmail: harverApplicationEmail || baseVariables.harverApplicationEmail,
                harverApplicationId: harverApplicationId || baseVariables.harverApplicationId,
                harverApplicantDetailPage: getApplicantDetailPageLink(harverAccountId, harverApplicationId),
            };
        }
        // currently encompasses all other modules
        case ChatbotPages.JourneyPage:
        default: {
            const {
                journeyId,
                journeyStepId,
                harverAccountId,
                harverAccountName,
                harverVacancyId,
                harverApplicationEmail,
                harverApplicationId,
            } = data as JourneyPageChatbotDependencies;

            // We need to send a formatted account name because zendesk uses a variable like name for account
            const FormattedAccountName = (harverAccountName || baseVariables.harverAccountName).replace(
                /[\-\/\[\]\(\), &#'.:]/gi,
                '_',
            );

            return {
                ...baseVariables,
                harverAccountId: harverAccountId || baseVariables.harverAccountId,
                harverAccountName: FormattedAccountName,
                harverVacancyId: harverVacancyId || baseVariables.harverVacancyId,
                harverApplicationEmail: harverApplicationEmail || baseVariables.harverApplicationEmail,
                harverApplicationId: harverApplicationId || baseVariables.harverApplicationId,
                harverApplicantDetailPage: getApplicantDetailPageLink(harverAccountId, harverApplicationId),
                harverJourneyId: journeyId || baseVariables.harverJourneyId,
                harverJourneyStepId: journeyStepId || baseVariables.harverJourneyStepId,
            };
        }
    }
};

export const getJourneyMetadata = async (): Promise<UserSession | undefined> => {
    try {
        return await getSession();
    } catch (err) {
        if (err.payload?.httpStatusCode === 403) {
            logger.debug('Candidate not logged in', { err });
            return;
        }
        throw err;
    }
};
