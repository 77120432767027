import { ok } from 'assert';
import { getChatbotVariables } from './helpers';
import { ChatbotHookDependencies, UseChatbotOutput } from './types';
import { useEffect, useMemo, useState } from 'react';
import { loadScript } from './scriptLoader';
import { OpenChatbot, SetConversationalFields, toZendeskConversationFields } from './zendeskBridge';

const ZENDESK_URL = 'https://static.zdassets.com/ekr/snippet.js';
const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
ok(ZENDESK_KEY, 'ZENDESK_KEY cannot be undefined');

export const useChatbot = (chatbotHookDependencies?: ChatbotHookDependencies): UseChatbotOutput => {
    const [chatbotDependencies, setChatbotDependencies] = useState(chatbotHookDependencies);

    useEffect(() => {
        loadScript({ id: 'ze-snippet', src: `${ZENDESK_URL}?key=${ZENDESK_KEY}` });
    }, []);

    const zendeskContext = useMemo(
        () => () => {
            if (!chatbotDependencies) return;
            const context = getChatbotVariables(chatbotDependencies.data, chatbotDependencies.language);
            return toZendeskConversationFields(window.location.href, context);
        },
        [chatbotDependencies],
    );

    // Zendesk will open the chat bot window, if the candidate has opened before and they
    // refresh the page some later time. We have to reset the conversational fields then,
    // to make sure they will show up correctly in the Zendesk Support Panel.
    useEffect(() => {
        SetConversationalFields(zendeskContext());
    }, [zendeskContext]);

    const openChatbotWindow = () => {
        SetConversationalFields(zendeskContext());
        OpenChatbot();
        return Promise.resolve();
    };

    return { openChatbotWindow, setChatbotDependencies };
};
